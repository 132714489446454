<template>
  <div  id="app">
    <b-navbar toggleable="lg" type="dark" variant="dark" :sticky="true" v-if="$store.state.account.user.isAuth">
      <b-navbar-brand to="/">Lichtspiel-Soziety</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/movies">Filme</b-nav-item>
          <b-nav-item href="#" disabled>Serien</b-nav-item>
          <b-nav-item href="#" disabled>Bücher</b-nav-item>
          <b-nav-item href="#" disabled>Spiele</b-nav-item>
          <b-nav-item to="/my-lichtspiele">Meine Sammlungen</b-nav-item>
          <b-nav-item href="#" disabled>Daten-Analyse</b-nav-item>
          <b-nav-item to="/verbesserungen">Feature-Requests</b-nav-item>
          <b-nav-item v-if="$store.state.account.user.isAdmin" to="admin">USER-Administration</b-nav-item>
          <b-nav-item to="/about">ueber</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Suchen"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0"><b-icon icon="search" aria-hidden="true"></b-icon> suchen</b-button>
          </b-nav-form>

          

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-icon icon="person-circle" aria-hidden="true"></b-icon> {{$store.state.account.user.name}}
            </template>
            <b-dropdown-item to="/user">Profil</b-dropdown-item>
            <b-dropdown-item @click="logout">Ich bin raus!</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    
    
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',

  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style>
html, body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  height: 100%;

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
