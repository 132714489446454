<template>
    <b-container fluid="sm" class="mt-2">
        <b-row>
            <b-col md="4">
                <db-image
                    :imagePath="movie.poster_path"
                    @image-error="handleImgNotFound(id)"/>

            </b-col>
            <b-col md="8">
                <b-row>
                    <b-col>
                        <b-icon v-if="movie.seen" icon="eye-fill" />
                        <b-icon v-if="movie.bookmarked" icon="bookmark-fill" />
                    </b-col>
                    <b-col class="text-right">
                        <b-icon
                        v-if="movie.hasTrophy"
                        variant="warning"
                        icon="trophy-fill"
                        />
                    </b-col>
                </b-row>
                <h2>{{ movie.titel }}</h2>
                <h6><b>Titel deutsch:</b> {{ movie.titel_deu ? movie.titel_deu: "-" }}</h6>
                <h6><b>Originaltitel:</b> {{ movie.org_title }}</h6>
                <b>Originalsprache:</b> {{ movie.org_sprache }}
                <p>
                    <b>Genres:</b> <i>{{ genreNames }} </i>
                </p>
                    

                <p class="text-justify"><b>Zusammenfasssung:</b>   {{ movie.overview }}</p>

                <p><b>Spiellänge:</b> {{ movie.spieldauer ? movie.spieldauer : "-" }} min</p>
                <p><b>Erscheinungsjahr:</b> {{ movie.erscheinungsjahr ? new Date(movie.erscheinungsjahr).toLocaleDateString() : "-" }}</p>

                <b>Bewertung:</b>
                <b-form-rating readonly :value="movie.bewertung / 2"></b-form-rating>

                <b>Deine Bewertung:</b>
                <b-form-rating readonly :value="movie.bewertung_user / 2"></b-form-rating>


            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import dbImage from '@/components/dbImage.vue'
import { getGenre } from '@/models/genres'
import { mapGetters } from "vuex"

export default {
    name: 'MovieDetails',
    components: {
        dbImage
    },
    data() {
        return {
            id: this.$route.params.id,
          

        }
    },

    methods: {
        jumpTopOnClick() {
            document.body.scrollTop = 0; // Safari
            document.documentElement.scrollTop = 0; // Chrome, Firefox, IE, Opera
        },
        handleImgNotFound(index) {
            this.$store.dispatch("movies/imageNotFound", index)      
        },
        test (gId) {
            return getGenre(gId)
        }

    },
    computed: {
        ...mapGetters('movies/', ['getMoviebyID']),
        
        movie() { return this.getMoviebyID(Number(this.$route.params.id)) },
        genreNames () { return this.movie.genre_ids.map(g => getGenre(g)).join(', '); }        
    },
    mounted() {
        this.$store.dispatch("movies/getMovieDetails", {movieID: this.id, userID: this.$store.state.account.user.id} )
  },
}
</script>

<style>

</style>