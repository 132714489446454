const genres =  [
    { id: 12, name: 'Adventure', name_deu: 'Abenteuer'},
    { id: 14, name: 'Fantasy', name_deu: 'Fantasy'},
    { id: 16, name: 'Animation', name_deu: 'Animation'},
    { id: 18, name: 'Drama', name_deu: 'Drama'},
    { id: 27, name: 'Horror', name_deu: 'Horror'},
    { id: 28, name: 'Action', name_deu: 'Action'},
    { id: 35, name: 'Comedy', name_deu: 'Komödie'},
    { id: 36, name: 'History', name_deu: 'Historie'},
    { id: 37, name: 'Western', name_deu: 'Western'},
    { id: 53, name: 'Thriller', name_deu: 'Thriller'},
    { id: 80, name: 'Crime', name_deu: 'Krimi'},
    { id: 99, name: 'Documentary', name_deu: 'Dokumentarfilm'},
    { id: 878, name: 'Science Fiction', name_deu: 'Science Fiction'},
    { id: 9648, name: 'Mystery', name_deu: 'Mystery'},
    { id: 10402, name: 'Music', name_deu: 'Musik'},
    { id: 10749, name: 'Romance', name_deu: 'Liebesfilm'},
    { id: 10751, name: 'Family', name_deu: 'Familie'},
    { id: 10752, name: 'War', name_deu: 'Kriegsfilm'},
    { id: 10770, name: 'TV Movie', name_deu: 'TV-Film'}
]


class Genre {
    constructor(id, name, name_deu) {
        this.id = id
        this.name = name
        this.name_deu = name_deu
    }
}

function getGenre(id) {
    return  genres.find(g => g.id === Number(id)).name
}

export {genres, Genre, getGenre} 



















