<template>
    <b-container fluent="sm">
        <h1>Nutzerübersicht</h1>

        <h4>Hallo {{ name }}</h4>
        <p>Hier kann man grundlegende Einstellungen für den Nutzer erledigen:</p>

        <b-form-group label="Nutzername:" class="mt-3">
            <b-input-group>
                <b-form-input v-model="name" type="text" placeholder="Nutzername" required></b-form-input>
                <b-input-group-append>
            
                <b-button variant="primary">ändern</b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>

        <b-form-group label="Email:" class="mt-1">
            <b-input-group>
                <b-form-input v-model="email" type="email" placeholder="Enter email" required></b-form-input>
                <b-input-group-append>
            
                <b-button variant="primary">ändern</b-button>
                </b-input-group-append>
            </b-input-group>           
        </b-form-group>

        <b-form-checkbox v-model="isV" size="lg" name="check-button" switch>
            ich möchte für andere Nutzer sichtbar sein.
        </b-form-checkbox>
        <b-form-checkbox disabled size="lg"  name="check-button" switch>
            ich möchte mit anderen Nutzer schreiben können.
        </b-form-checkbox>

        <label class="mt-2">Gruppenauswahl:</label>
        <b-form-select v-model="gruppenwahl" :options="gruppen" multiple :select-size="4"></b-form-select>

        <b-button  class="mt-2" block variant="outline-primary">{{ canAdd? 'ich kann Daten eintragen' : 'Ich möchte Daten eintragen können'}} </b-button>
        <b-row align-v="stretch" class="mt-5">
            <b-col>
                <b-button block variant="outline-danger">Nutzer löschen!!!</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import store from "../store";

export default {
    name: "UserView",
    data() {
        return {
            name: store.state.account.user.name,
            isVis: store.state.account.user.isVisible,
            email: store.state.account.user.email,
            canAdd: store.state.account.user.canAddMovies,
            gruppen: [
                {value: 'a', text: 'Gruppe-A' },
                {value: 'b', text: 'Gruppe-B' },
                {value: 'c', text: 'Gruppe-C' },
            ],
            gruppenwahl: []
        }
    },
    methods: {
        makeToast(variant = null, title = null, message = null) {
            this.$bvToast.toast(message, {
            title: title,
            variant: variant,
            solid: true
            })
        }
    },
    watch: {
        '$store.state.account.resultInfo': function() {
            if (store.state.account.resultInfo == 0)
                this.makeToast('danger', 'Fehler', store.state.account.infoMSG)
            if (store.state.account.resultInfo == 1)
                this.makeToast('success', 'Gespeichert', store.state.account.infoMSG)
        }
    },
    computed: {
        isV: {
            get() { return this.$store.getters["account/isVisible"] },
            set(newValue) {
                store.dispatch("account/changeVis", newValue);
                return newValue;
            }
        }
    }
}
</script>

<style>

</style>