<template>
  <b-container fluent="sm">
    <h4>Hallo {{ name }}</h4>
    <p>
      Hier kannst du Listen erstellen von gesehenen Filmen, Serien, gelesenen Büchern und gepielten Spielen sowie Listen anlegen, was du dir noch ansehen möchtest.
    </p> 
      
    <b-row class="my-2">
      <b-col cols="3" class="pr-1"> <b-button v-b-modal.modal-movie-tinder block variant="primary"><b-icon icon="plus-square"></b-icon> Filme</b-button></b-col>
      <b-col cols="3" class="px-1"> <b-button block variant="primary"><b-icon icon="plus-square"></b-icon> Serien</b-button></b-col>
      <b-col cols="3" class="px-1"> <b-button block variant="primary"><b-icon icon="plus-square"></b-icon> Bücher</b-button></b-col>
      <b-col cols="3" class="pl-1"> <b-button block variant="primary"><b-icon icon="plus-square"></b-icon> Spiele</b-button></b-col>
    </b-row>

    <b-modal id="modal-movie-tinder" 
      :hide-header="true"
      headerClass='p-2 border-bottom-0' 
      footerClass='p-2 border-top-0' 
      body-bg-variant="secondary"
      body-text-variant="light"
      footer-bg-variant="secondary"
      centered 
      >
      <template #default>
        <b-container fluid class="p-0">
          <div v-if="movieTinder.length > 0">
            <b-row>
              <b-col cols="1" class="p-0">
                <b-button squared style="height:100%" block variant="dark" @click="tinderClick_Add(true)"><b-icon icon="eye"/></b-button>
              </b-col>
              <b-col class="p-0">
                <swipe-image :image-path="movieTinder[0].poster_path" @rechts-wischen="tinderClick_Del" @links-wischen="tinderClick_Add" @runter-wischen="tinderClick_Add"/>
              </b-col>
              <b-col cols="1" class="p-0">
                <b-button squared style="height:100%" block variant="dark" @click="tinderClick_Del()"><b-icon icon="eye-slash"/></b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="p-0">
                <b-button squared block variant="dark" @click="tinderClick_Add()"><b-icon icon="bookmark-plus"/></b-button>
              </b-col>
            </b-row>
          </div>
          <div v-else class="text-center">
            <h5>Keine Filme in der Kiste!</h5>
            <b-Button variant="dark" @click="{tinderListIds = []}">Liste nicht gesehener Filme leeren.</b-Button>
          </div>
        </b-container>
      </template>

      <template #modal-footer="{ hide }">        
        <b-button v-b-toggle.collapse-movieTinder variant="outline-light" size="sm"><b-icon icon="filter-circle"/> </b-button>
        <b-collapse id="collapse-movieTinder" class="mt-2 w-100">
          <b-input-group size="lg" class="mb-2">
            <b-form-input aria-label="Large text input with switch"></b-form-input>
            <b-dropdown text="Genre" variant="light">
              <b-dropdown-item>Action C</b-dropdown-item>
              <b-dropdown-item>Action D</b-dropdown-item>
            </b-dropdown>
            <b-input-group-append is-text>
              <b-form-checkbox switch variant="light" class="mr-n2">
                <span class="sr-only">Switch for previous text input</span>
              </b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
          Filterergebnis: {{movieTinder.length}} aus {{ db_movie_list.length }}
        </b-collapse>
        
        <b-button size="sm" variant="outline-light" @click="hide()">
          <b-icon icon="x-circle-fill"/>
        </b-button>
      </template>
    </b-modal>

    <div class="d-flex w-100 justify-content-between">
      <h1>Meine Filme</h1>
      <b-button-group>
        <b-button :pressed="movieListSelected" @click="movieListSelected = !movieListSelected" variant="outline-dark"><b-icon icon="list"></b-icon></b-button>
        <b-button :pressed="!movieListSelected" @click="movieListSelected = !movieListSelected" variant="outline-dark"><b-icon icon="grid-fill"/></b-button>
        <b-button v-b-toggle.collapse-myMovies variant="dark"><b-icon icon="arrow-up-square-fill"></b-icon></b-button>
      </b-button-group>
    </div>
    
    <b-collapse visible id="collapse-myMovies" >

      <b-tabs content-class="mt-3" justified>
        <b-tab lazy active>
          <template #title>
            Anzusehende Filme <b-badge variant="dark">{{ watchMovies.length }} </b-badge>   
          </template>
          <div class="moviegroup">
            <movie-card 
              v-for="(my_movie, index) in watchMovies" :key="my_movie.id"
              :index="index"
              :movie="my_movie"
              :show-seen-btn="true"
            > </movie-card>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            Gesehene Filme <b-badge variant="dark">{{ seenMovies.length }} </b-badge>   
          </template>
          <div class="moviegroup">
            <movie-card 
              v-for="(my_movie, index) in seenMovies" :key="my_movie.id"
              :index="index"
              :movie="my_movie"
            > </movie-card>        
          </div>
        </b-tab>
        <b-tab lazy title="Meine Filmliste">
          <template #title>
            Meine Filmliste <b-badge variant="dark">{{ seenMovies.length }} </b-badge>   
          </template>
          TODO
        </b-tab>
      </b-tabs>
    </b-collapse>
    <h1>Meine Serien</h1>
    <h1>Meine Games</h1>
    <h1>Meine Bücher</h1>
    <h1>Meine Leihliste</h1>
  </b-container>
</template>
<script>
import SwipeImage from '@/components/SwipeImage.vue'
import MovieCard from '@/components/MovieCard.vue'

export default {
  name: "User-Lichtspiele",
  components: {
    SwipeImage,
    MovieCard,
  },
  data() {
    return {
      name: this.$store.state.account.user.name,
      isVis: this.$store.state.account.user.isVisible,
      email: this.$store.state.account.user.email,
      canAdd: this.$store.state.account.user.canAddMovies,
      xDown: null,
      yDown: null,
      
      gruppen: [
        {value: 'a', text: 'Gruppe-A' },
        {value: 'b', text: 'Gruppe-B' },
        {value: 'c', text: 'Gruppe-C' },
      ],
      gruppenwahl: [],

      /* db_movie_list: [
        { id: 1, title: 'Star-Wars I', imgTmb: 'Star-Wars_E1_tmb.jpg'},
        { id: 2, title: 'Star-Wars IV', imgTmb: 'Star-Wars_E4_tmb.jpg'},
        { id: 8, title: 'Star-Wars VII', imgTmb: 'Star-Wars_E7_tmb.jpg'},
        { id: 9, title: 'Star-Wars VIII', imgTmb: 'Star-Wars_E8_tmb.jpg'},
      ], */

      // my_movie_list: this.$store.state.movies.movies,

      tinderListIds: [],

      movieListSelected: true,
      listOptions: [
        {text:'Liste', value: true},
        {text:'Bilder', value: false}
      ]
    }
  },
  methods: {
    test(e) {
      console.log("Hey was geht", e)
    },
    makeToast(variant = null, title = null, message = null) {
      this.$bvToast.toast(message, {
      title: title,
      variant: variant,
      solid: true
      })
    },

    tinderFilter () {
      alert('test')
    },

    tinderClick_Add(seen = false) {
      if (this.movieTinder.length == 0) return;

      let mov = this.movieTinder[0]
      if (seen) {
        this.$store.dispatch("movies/addSeenMovies", mov.id)

        //mov.seen = seen
      } else {
        this.$store.dispatch("movies/addBookmark", mov.id)
        // mov.bookmarked = true
      }

      // this.my_movie_list.push(mov)
    },

    tinderClick_Del() {
      if (this.movieTinder.length == 0) return;
      this.tinderListIds.push(this.movieTinder[0].id)
    },

  },
  computed: {
    my_movie_list() { return this.$store.getters["movies/getUserMovies"] },
    db_movie_list() { return this.$store.getters["movies/getMovieList"] },
    seenMovies() { return this.$store.getters["movies/getSeenUserMovies"]},
    watchMovies() { return this.$store.getters["movies/getBookmarkedUserMovies"]},
    movieTinder() {
      let filterids = this.my_movie_list.map(movie => movie.id);
      console.log(filterids)
      let listIds = [...this.tinderListIds, ...filterids]
      
      return this.db_movie_list.filter(movie => !listIds.includes(movie.id))
    }
  },
  mounted() {
    this.$store.dispatch("movies/getUserMovies", this.$store.state.account.user.id )
    // console.log('mounted');    
  },
  beforeDestroy() {
    // console.log('beforeDestroy');
  }

}
</script>

<style scoped>

.moviegroup {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  align-content: start;
}


</style>
