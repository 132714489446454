// Eilers Systems-Engineering
// Jan Eilers
// jan@eilers-systemsengineering.de
// Version: 04.01.2023

//import router from "../../router";
import User from "../../models/user";
import axios from "axios";

const state = () => ({
    user: new User(),
    infoMSG: '',
    resultInfo: -1
})

const getters = {
    isVisible: state => state.user.isVisible,
    userID: state => state.user.id,
    jwtToken: state => state.user.token
}

const mutations = {
    SUCCESS(state, msg) {
        state.resultInfo = 1;
        
        state.infoMSG = `Änderung gespeichert. ${msg}`;
    },

    ERROR(state, error) {
        state.resultInfo = 0
        state.infoMSG = 'Änderung konnte nicht gespeichert werden: ' + error.message;
    },

    SETUSER(state, {user}){        
        state.user = Object.assign(state.user, user);
    },

    RESETUSER (state) {
        state.user = new User();      
    },

    CHANGEVISIBILITY (state) {
        state.user.isVisible = !state.user.isVisible;
    },

    RESET_RESULT_INFO (state) {        
        state.resultInfo = -1
    }


}

const actions = {
    setuser (commit, user) {        
        commit("SETUSER", user);
    },
    changeVis ({ commit, getters, rootState }) {
        commit("CHANGEVISIBILITY");        
        
        //let test = axios
        axios
            .post(`${rootState.serverConfig.host}/users/change.php`,  
            {
                // payload
                id: getters.userID,
                field: 'isVisible',
                value: getters.isVisible
            },
            {
                // configuration
                headers: {'Content-Type': 'application/x-www-form-urlencoded' } 
            })        
            .then((response) => {
                commit('SUCCESS', response.data.message);                
                return response
            })
            .catch( (error) => {
                commit('ERROR', error)
                console.error(error)
            })
            .finally (() => {                
                commit("RESET_RESULT_INFO")                
            });

        // console.log(test)
    }
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state
};