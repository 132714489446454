<template>
  <b-container fluid="sm" id="Register">
    
    <b-row class="vh-100" align-v="center" align-h="center">
        <b-col cols="12" md="8">
            <b-card bg-variant="light">
                <h2 class="mb-5 text-center">Registrieren</h2>
                <b-alert :show="dismissCountDown" @dismiss-count-down="countDownChanged" variant="danger" dismissible fade>
                    Achtung! {{alertMessage}}
                 </b-alert>
                
                <b-form @submit.stop.prevent="onSubmit">
                    <b-form-group label-cols="4" label-cols-lg="3" label="Name:" label-for="username" label-align-sm="right" class="my-1">
                        <b-form-input type="text" class="form-control" id="username" v-model="form.username" required></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="3" label="E-Mail:" label-for="email" label-align-sm="right" class="my-1">
                        <b-form-input type="email" class="form-control" id="email" v-model="form.email" required></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="3" label="Password:" label-for="username" label-align-sm="right" class="mt-3 mb-1">
                        <b-form-input type="password" class="form-control" id="password" v-model="form.password" required></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="3" label="Confirm Password:" label-for="username" label-align-sm="right" class="my-1">
                        <b-form-input type="password" class="form-control" id="confirmPassword" v-model="form.password_confirmation" required></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="3" label="Sichtbarkeit:" label-for="username" label-align-sm="right" class="my-1">
                        <b-form-checkbox v-model="form.isVisible" name="check-button" switch size="lg">
                            <b-icon v-if="form.isVisible" icon="eye"></b-icon> <b-icon v-else icon="eye-slash"></b-icon> 
                        </b-form-checkbox>
                    </b-form-group>

                    <div class="mt-3 float-right">
                        <b-button-group>
                            <b-button size="lg" to="login" variant="secondary">Abbruch</b-button>
                            <b-button size="lg" type="submit" variant="primary">Registrieren</b-button>
                        </b-button-group>
                    </div>
                    
                </b-form>
                
            </b-card>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'RegisterView',
    data() {
        return {            
            dismissSecs: 5,
            dismissCountDown: 0,
            alertMessage: '',
            form: {
                username: '',
                email:"",
                password: '',
                password_confirmation: '',
                isVisible: false
            }
        }
    }    ,
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert(message) {
        this.alertMessage = message
        this.dismissCountDown = this.dismissSecs
      },
      resetPW() {        
        this.form.password = ''
        this.form.password_confirmation = ''       
      },
      makeToast(variant = null, title = null, message = null) {
        this.$bvToast.toast(message, {
          title: title,
          variant: variant,
          solid: true
        })
      },
      onSubmit() {
        //event.preventDefault()
        //debugger; // eslint-disable-line no-debugger
        if(this.form.password.length <8){            
            this.showAlert('Password muss mindesten 8 Zeichen lang sein')
            this.resetPW()            
            return
        }
        if(this.form.password_confirmation != this.form.password){
            this.showAlert('Password muss gleich sein')
            this.resetPW()            
            return
        }       
        console.log(JSON.stringify(this.form))
        console.debug(`${this.$store.state.serverConfig.host}`)
        try {    
            axios
                .post(`${this.$store.state.serverConfig.host}/register/`,
                    this.form,
                 /* { 
                    name: this.form.username, 
                    email: this.form.email,
                    password: this.form.password,
                    isVisible: this.form.isVisible
                }, */
                {
                    // configuration
                    headers: {'Content-Type': 'application/x-www-form-urlencoded' } 
                })        
                .then(response => {
                    console.debug(response) // TODO: remove this till deployment

                    if (response.data.isRegistered) {
                        // Successfully
                        this.makeToast('info', 'Registrierung erfolgreich', 'Sie werden vom Administrator per E-Mail informiert, dass Sie die Datenbank nutzen können.');
                    } else {
                        // Fehler
                        if (response.data.includes("Duplicate entry")) {
                            this.makeToast('danger', 'Achtung', 'Registrierung fehlgeschlagen; Nutzername schon vergeben.');
                        } else {
                            this.makeToast('danger', 'Achtung', 'Registrierung fehlgeschlagen');
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Achtung', 'Registrierung fehlgeschlagen; console'); 
                    console.error(error)
                });
        } catch (err) {
            this.makeToast('danger', 'Achtung', 'Registrierung fehlgeschlagen; console');
            console.error(err);
        }


      }
    }
}
</script>

<style>

</style>