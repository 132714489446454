<template>
    <b-img-lazy fluid-grow :src="calImagePath" @error="handleError" />
</template>

<script>
export default {
  props: {
    imagePath: {
      type: String,
      required: true
    }
  },
  computed: {
    calImagePath() {
      try {
        return require(`@/assets/Movies/thumbs${this.imagePath}`)
      } catch (error) {
        this.$emit('image-error')
        return require(`@/assets/default_notFound.png`)
      }
    }
  },
  methods: {
    handleError() {
        this.$emit('image-error')
    }
  }
}

</script>