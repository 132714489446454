<template>
  <b-container fluid="sm" class="home">
    <h1>Filme</h1>
    <h1>Serien</h1>
    <h1>Events</h1>
    
    
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </b-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  }
}
</script>
