import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import UserView from '../views/UserView.vue'
import MoviesView from '../views/MoviesView.vue'
import MyLichtspieleView from '../views/MyLichtspieleView.vue'
import MovieDetails from "../views/MovieDetails.vue" 
import ImproView from '../views/ImproView.vue'
import store from "../store";

Vue.use(VueRouter)

const authReqired = (to, from, next) => {
  if (!store.state.auth.user.isAuth) {  //auth.user.isAut
    next("/login");
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: authReqired
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    beforeEnter: authReqired
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/user',
    name: 'User',
    component: UserView,
    beforeEnter: authReqired
  },
  {
    path: '/movies',
    name: 'Movies',
    component: MoviesView,
    beforeEnter: authReqired
  },
  {
    path: '/my-lichtspiele',
    name: 'MyLichtspiele',
    component: MyLichtspieleView,
    beforeEnter: authReqired
  },
  {
    path: '/verbesserungen',
    name: 'Verbesserungen',
    component: ImproView,
    beforeEnter: authReqired
  },
  {
    path: '/moviedetails/:id',
    name: 'MovieDetails',
    component: MovieDetails,
    beforeEnter: authReqired
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
