// Eilers Systems-Engineering
// Jan Eilers
// jan@eilers-systemsengineering.de
// Version: 25.02.2020

import router from "../../router";
import User from "../../models/user";
import axios from "axios";

const state = () => ({
  user: new User(),
  loading: false,
  showerror: false,
  showinfo: false,
  errorInfo: "",
  infoMsg: ""
});

const getters = {
  isAuthenticated: state => !!state.user.token,
  getToken: state => state.user.token,
  getLoading: state => state.loading,
  getShowerror: state => state.showerror,
  getShowinfo: state => state.showinfo,
  getInfoMsg: state => state.infoMsg,
  getErrorInfo: state => state.errorInfo
};

const mutations = {
  LOGIN(state, { user }) {
    state.loading = false;    
    state.user = Object.assign(state.user, user);
    router.push("/");
  },
  CANPASS(state) {
    state.loading = true;
  },
  LOGOUT(state, msg = null) {
    console.log('logout')
    localStorage.removeItem("user-token");
    state.user = new User();
    state.showinfo = true;
    state.loading = false;    
    if (msg) {
      state.infoMsg = msg;
    }else
      state.infoMsg = "Nutzer ist abgemeldet!";
    if(router.history.current.path != "/login")
      router.push("/login");
  },
  ERROR(state, error) {
    state.errorInfo = error;
    state.showinfo = true;
    state.showerror = true;
    state.loading = false;
  },
  LOADING(state) {
    state.showinfo = false;
    state.showerror = false;
    state.loading = true;
  }
};

const actions = {
  login({ commit, rootState }, { name, password, keepLogedIn }) {
    commit("LOADING");

    console.log(rootState.serverConfig.host)

    try {      
      axios
        .post(`${rootState.serverConfig.host}/login/`, 
        {
          // payload
          name,
          password,
          keepLogedIn
        },
        {
          // configuration
          headers: {'Content-Type': 'application/x-www-form-urlencoded' } 
        })        
        .then(function(response) {
          console.log(response)

          if (response.data.isAuth) {
            if (keepLogedIn)
              localStorage.setItem("user-token", response.data.token);
             
            commit("LOGIN", { user: response.data });
            commit('account/SETUSER', { user: response.data }, { root: true });
          } else if (response.data.notActive) {
            commit("LOGOUT", "Nutzer noch nicht freigeben");
            commit('account/RESETUSER', null, {root: true});
          } else if (response.data.noUser) {
            commit("LOGOUT", "Nutzer nicht bekannt.");
            commit('account/RESETUSER', null, {root: true});
          }else {
            commit("LOGOUT");
            commit('account/RESETUSER', null, {root: true});
          }
        })
        .catch(function(error) {
          commit("ERROR", error);
        });
    } catch (err) {
      console.log("error: " + err);
    }
  },
  canPass({ commit, rootState }, token) {
    axios
      .post(`${rootState.serverConfig.host}/login/`,
        { 
          token 
        },
        {
          // configuration
          headers: {'Content-Type': 'application/x-www-form-urlencoded' } 
        })
      .then(function(response) {
        if (response.data.isAuth) {
          commit("LOGIN", { user: response.data });
          commit('account/SETUSER', { user: response.data }, {root: true});
        } else {
          commit("LOGOUT");
          commit('account/RESETUSER', null, {root: true});
        }
      })
      .catch(function(error) {
        console.log(error);
        //commit("ERROR", error);
      });
    commit("CANPASS");
  },
  logout({ commit }) {
    commit("LOGOUT");
    commit('account/RESETUSER', null, {root: true});
  }
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
};
