<template>
    <b-container fluent="sm">
        <b-button v-b-modal.improvement class="mt-2"><b-icon icon="plus"/> Verbesserung</b-button>
        <b-modal id="improvement" title="Verbesserung" @ok="handleOK" >
            <label for="textarea-impro">Titel: </label>
            <b-form-input v-model="form.titel" placeholder="Titel"></b-form-input>
            <label for="textarea-impro">Verbesserung:</label>
            <b-form-textarea
                id="textarea-impro"
                v-model="form.verbesserung"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
            ></b-form-textarea>

        </b-modal>

        <H1>Verbesserungsvorschläge</H1>

        <b-list-group>
            <b-list-group-item v-for="item in impros" :key="item.id" class="flex-column align-items-start" >
                <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.titel}}</h5>
                
                <small>DATE</small>
                </div>

                <p class="mb-2">
                {{ item.text }}
                </p>

                <div class="d-flex w-100 justify-content-between">
                    <small>offen</small>
                    <b-button variant="danger"><b-icon icon="trash"/></b-button>
                </div>
            </b-list-group-item>

        </b-list-group>


        
    </b-container>
</template>

<script>
function getImprosfromDB () {
    console.log('getImprosfrom')
    return null;
}


export default {
    name: 'ImproView',
    data() {
        return {
            form: {
                titel:'',
                verbesserung:''
            },
            impros: [{titel: 'titel', text: 'verbesserung'}]
        }
    },
    methods: {
        handleOK() {
            if (this.form.verbesserung === '') {
                alert('TEST');
                return false;
            }

            this.impros.push( { titel: this.form.titel, text:  this.form.verbesserung})

            this.form.titel = '';
            this.form.verbesserung = '';
        },
        
    },
    mounted() {
        getImprosfromDB();

    }

}
</script>

<style>

</style>