<template>
<b-container fluid="sm" id="Login">
    
    <b-row class="vh-100" align-v="center" align-h="center">
        <b-col cols="12" md="8">        
            <b-form id="loginForm" @submit="onSubmit">
                <h1 class="mb-5 text-center">Lichtspiel-Soziety</h1>
                
                <b-form-group id="input-group-1" label="Username:" label-for="input-1" class="my-1">
                    <b-form-input size="lg" id="input-username" v-model="form.username" type="text" placeholder="Enter Username" required></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-2" label="Password:" label-for="input-1" class="my-1">
                    <b-form-input size="lg" id="input-passaord" v-model="form.password" type="password" placeholder="Enter Password" required></b-form-input>
                </b-form-group>
                <b-form-checkbox class="mt-2" v-model="form.keepLogedIn">Angemeldet bleiben</b-form-checkbox>
                <b-button size="lg" block variant="outline-primary" type="submit">
                    <b-spinner v-if="loading" small></b-spinner>login
                </b-button>
                <router-link class="float-right" to="/register">Registrieren</router-link>
                <b-alert class="mt-4" :show="showinfo" dismissible variant="warning">Information: {{ infoMsg }}</b-alert>
                <b-alert :show="showerror" dismissible variant="danger">{{ errorMsg }}</b-alert>
            </b-form>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import store from "../store";

export default {
    name: 'LoginView',
    data() {
        return {
            form: {
                username:'',
                password:'',
                keepLogedIn:false,
            }
        }
    },
    computed: {
        loading()   { return this.$store.getters["auth/getLoading"] },
        errorMsg()  { return this.$store.getters["auth/getErrorInfo"] },
        showerror() { return this.$store.getters["auth/getShowerror"] },
        showinfo()  { return this.$store.getters["auth/getShowinfo"] },
        infoMsg ()  { return this.$store.getters["auth/getInfoMsg"] }
    },
    beforeRouteEnter(to, from, next) {
        if (store.getters["auth/isAuthenticated"])
            store.dispatch("auth/canPass", store.getters["auth/getToken"]);
        else {
            console.log("token is not available");
            next();
        }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.$store.dispatch("auth/login", {
            name: this.form.username,
            password: this.form.password,
            keepLogedIn: this.form.keepLogedIn
        });
        console.log(JSON.stringify(this.form))
      }
    }
}
</script>

<style>

</style>