export default class User {
    constructor() {
      this.id = null;
      this.name = "";
      this.level = "";
      this.isAuth = false;
      this.token = localStorage.getItem("user-token") || "";
      this.canAddMovies = false;
      this.canChat = false;
      this.isAdmin = false;
      this.isVisible  =false;
      this.email = "";
    }

/*     register(name, password, isVisible) {
        
    } */
}