<template>
  <b-container :fluid="makeFluid">
    <b-form-checkbox class="float-right" v-model="makeFluid" switch size="lg">
      <b-icon v-if="makeFluid" icon="arrows-collapse" rotate="90" />
      <b-icon v-else icon="arrows-expand" rotate="90" />
    </b-form-checkbox>
    <b-button-group class="my-2">
      <b-button v-b-toggle.filterMovie variant="dark"><b-icon icon="filter"></b-icon> Filter</b-button>
      <b-button v-b-toggle.sortMovie variant="dark"><b-icon icon="sort-down"></b-icon> Sortieren</b-button>
      <b-button v-b-toggle.searchMovie variant="dark"><b-icon icon="search"></b-icon> Suchen</b-button>
    </b-button-group>

    <b-collapse visible id="filterMovie" class="my-2">
      <b-card>
        <h4>Filter</h4>
        <b-dropdown size="lg" block variant="dark" id="genreFilter" text="Genre-Filter" menu-class="w-100" class="my-2">
          <b-dropdown-item @click="allFilterGernres"> <b>{{ useGenreFilter ? 'ALLE ANWÄHLEN' : 'ALLE ABWÄHLEN' }}  </b></b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-for="genre in gennreOptions" :key="genre.id" :value="genre.value" @click.native.capture.stop="handleFilterGenreIDs(genre.value)">
            <b-icon v-if="genreSelected.includes(Number(genre.value))" icon="check"/> 
            <b-icon v-else icon="blank"/>
            {{genre.text }} </b-dropdown-item>
        </b-dropdown>

        <!-- <b-button  variant="info" @click="testFilter"><b-icon icon="filter"></b-icon> Filter</b-button> -->

      </b-card>
    </b-collapse>

    <b-collapse id="sortMovie" class="my-2">
      <b-card>
        <h4>Sortieren</h4>


      </b-card>
    </b-collapse>

    <b-collapse id="searchMovie" class="my-2">
      <b-card>
        <h4>Suche</h4>
        <b-form-group label="Search tags" label-for="tag-search-input" label-cols-md="auto" class="mb-0" label-size="sm">          
          <b-form-input v-model="search" id="tag-search-input" type="search" size="sm" autocomplete="off" list="filmtitle"></b-form-input>
        </b-form-group>

        <datalist id="filmtitle">
          <option v-for="film in db_movie_list" :key="film.id" :value="film.titel"></option>
        </datalist>

      </b-card>
    </b-collapse>




    <h4>Filme <b-badge variant="dark" class="float-right">{{ db_movie_list.length }} / {{ $store.state.movies.rowCount }}</b-badge></h4>
    <div class="moviegroup">
      <movie-card v-for="(db_movie, index) in db_movie_list" :key="index" :movie="db_movie" :index="db_movie.id"></movie-card>
    </div>

    <b-button ref="flaotingButton" id="flaotingButton" size="lg" variant="dark" @click="jumpTopOnClick"> <b-icon icon="arrow-up" /></b-button>

  </b-container>
</template>

<script>
import MovieCard from '@/components/MovieCard.vue'
import { genres } from '@/models/genres'

export default {
  name: "Filme-View",
  components: {
    MovieCard
  },
  data() {
    return {
      makeFluid: false,
      scrollposition: 0,
      search: '',
      genreSelected: genres.map(g  =>g.id),
      gennreOptions: genres.map(g  => {return {value: g.id, text: g.name}}),
      useGenreFilter: false,
      filteredList: []
    }
  },
  methods: {
    jumpTopOnClick() {
      document.body.scrollTop = 0; // Safari
      document.documentElement.scrollTop = 0; // Chrome, Firefox, IE, Opera
    },
    handleScroll() {
      let flaotingButton = this.$refs.flaotingButton;

      if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
        flaotingButton.style.display = "block";
      } else {
        flaotingButton.style.display = "none";
      }
    },
    handleFilterGenreIDs(id) {
      const index = this.genreSelected.indexOf(id);

      if (index > -1) {
        this.genreSelected.splice(index, 1);
      } else {
        this.genreSelected.push(id);
      }
    },
    allFilterGernres() {
      this.useGenreFilter = !this.useGenreFilter
      if (this.useGenreFilter) {
        this.genreSelected = []
      }
      else {
        this.genreSelected = genres.map(g  =>g.id)
      }
    }
  },

  computed: {
    db_movie_list() { 
      
      let result =this.$store.getters["movies/getMovieList"].filter(item => item.genre_ids.some(id => this.genreSelected.includes(Number(id))))
      if(this.search != '')
        return result.filter(mov => mov.titel.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
      else
        return result
  },
  },

  mounted() {
    // console.log('mounted');
    window.addEventListener('scroll', this.handleScroll)

    this.$store.dispatch("movies/countMovies");

    let counts = this.db_movie_list.reduce((acc, obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'seen') && obj.seen == true)
        acc++
      if (Object.prototype.hasOwnProperty.call(obj, 'bookmarked') && obj.bookmarked == true)
        acc++;
      return acc;
    }, 0);

    if (this.db_movie_list.length == 0 || this.db_movie_list.length == counts) {
      this.$store.dispatch("movies/getMovies")
    }

 /*    fetch('https://swapi.dev/api/films/')
    .then(res => res.json())
    .then(res => {
      this.films = res.results.map(f => {
        return f.title;
      })
    }) */
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
} 
</script>

<style>
.moviegroup {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  align-content: start;
  margin-bottom: 20px;
}

#flaotingButton {
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 100%;
  z-index: 99;
}
</style>