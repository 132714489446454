<template>    
    <b-img ref="tinderMovieImage" :src="calImagePath" fluid-grow alt="Responsive image"></b-img>    
</template>

<script>
export default {
    name: 'SwipeImage',
    props: {
        msg: String,
        imagePath: String
    },
    methods: {
        handleTouchStart (event) {
        const firstTouch = event.touches[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
        },

        handleTouchMove (event) {
        if (!this.xDown || !this.yDown) return;
        
        let xUp = event.touches[0].clientX;
        let yUp = event.touches[0].clientY;
        
        let xDiff = this.xDown - xUp;
        let yDiff = this.yDown - yUp;
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0){
                // console.log("links")
                this.$emit("links-wischen", true)
            } else {
                // console.log("rechts")
                this.$emit("rechts-wischen")
            } 
        } else {
            if (yDiff > 0){
                // console.log("up")
            } else {
                // console.log("down")
                this.$emit("runter-wischen", false)
            }
        }

        this.xDown = null;
        this.yDown = null;
        }
    },
    computed: {
        calImagePath() {
        try {
            return require(`@/assets/Movies/thumbs${this.imagePath}`)
        } catch (error) {
            this.$emit('image-error')
            return require(`@/assets/default_notFound.png`)
        }
        }
    },
    mounted() {
        // console.log("IMG mounted")
        const tindermovieIMG =  this.$refs.tinderMovieImage
        
        tindermovieIMG.addEventListener('touchstart', this.handleTouchStart, false);
        tindermovieIMG.addEventListener('touchmove', this.handleTouchMove, false);
    },
    beforeDestroy() {
        // console.log('beforeDestroy');
        const tindermovieIMG =  this.$refs.tinderMovieImage
        tindermovieIMG.removeEventListener('touchstart', this.handleTouchStart, false);
        tindermovieIMG.removeEventListener('touchmove', this.handleTouchMove, false);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
} */

</style>
  