import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
import Auth from './modules/auth'
import Movies from './modules/movies'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  state: {       
    serverConfig: {
      host: process.env.NODE_ENV === 'production' ?  window.location.origin + process.env.VUE_APP_API : process.env.VUE_APP_API
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: Auth,
    account: User,
    movies: Movies,
  }
})
