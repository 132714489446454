<template>
  <b-card
    headerClass="p-0 border-bottom-0"
    footerClass="p-1"
    body-class="p-1"
    class="mb-2"
  >
    <template #header>
      <db-image
        :imagePath="movie.poster_path"
        @image-error="handleImgNotFound(index)"
      />
    </template>

    <b-row align-v="end" style="height: 100%">
      <b-col>
        <b-row>
          <b-col>
            <h4 class="text-center" v-if="movie.showMovieTitle">
              {{ movie.titel }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-icon v-if="movie.seen" icon="eye-fill" />
            <b-icon v-if="movie.bookmarked" icon="bookmark-fill" />
          </b-col>
          <b-col class="text-right">
            <b-icon
              v-if="movie.hasTrophy"
              variant="warning"
              icon="trophy-fill"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="dark" block @click="clickDetails">Details</b-button>
            <b-button v-if="showSeenBtn" variant="info" block @click="clickSeen"><b-icon icon="eye"></b-icon> gesehen</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <template #footer>
      <b-form-rating readonly :value="movie.bewertung / 2"></b-form-rating>
    </template>
  </b-card>
</template>

<script>
import dbImage from '@/components/dbImage.vue'

export default {
    components: {
    dbImage
    },
    props: {
        movie: { type: Object, required: true},
        index: { type: Number, required: true},
        showSeenBtn: { type: Boolean, default: false}
    },
    data() {
        return {

        }
    },
    methods: {
        handleImgNotFound(index) {
            this.$store.dispatch("movies/imageNotFound", index)      
        },
        clickDetails() {
            // open in new View
            this.$router.push({ path: `/moviedetails/${this.movie.id}`})

        },
        clickSeen() {
            // change properties in database by ID and update Array
            this.$store.dispatch("movies/addSeenMovies", this.movie.id)
        }
    }

};
</script>

<style>
</style>