import Movies from "../../models/movies";
import axios from "axios";

const state = () => ({
    movies: [],
    userMovies: [],
    rowCount: 0,
    infoMSG: '',
    resultInfo: -1
})

const getters = {
    getMovieList: state => state.movies,
    getUserMovies: state => state.movies.filter(movie => { if(movie.seen || movie.bookmarked) return movie }),
    getSeenUserMovies: state => state.movies.filter(movie => movie.seen),
    getBookmarkedUserMovies: state => state.movies.filter(movie => movie.bookmarked),
    getMoviebyID: state => (id) => state.movies.find(movie => movie.id === id)
}

const mutations = {
    SET_MOVIES(state, movies) {
        console.log(movies)
        for (const movie of movies) {

            let ind = state.movies.findIndex(mov => mov.id === movie.id) 
            
            // update if movie is already there            
            if(ind >= 0) {
                Object.assign(state.movies[ind], movie)
            }
            else{
                state.movies.push(Object.assign(new Movies(), movie))
            }
        }
    },

    IMAGE_NOT_FOUND (state, id) {
        const mov = state.movies.find(movies => movies.id === id)
        mov.showMovieTitle = true;
        mov.poster_path = require("./../../assets/default_notFound.png")
    },

    CHANGE_MOVIE_SEEN (state, id) {
        const mov = state.movies.find(movies => movies.id === id)
        mov.bookmarked = false
        mov.seen = true        
    },

    CHANGE_MOVIE_BOOKMARK (state, id) {
        const mov = state.movies.find(movies => movies.id === id)
        mov.bookmarked = true
        mov.seen = false
    },

    COUNT_MOVIES (state, rowCount) {
        state.rowCount = rowCount;
    }
}

const actions = {
    async countMovies ({commit, rootState, rootGetters}) {
        try {
            let path = `${rootState.serverConfig.host}/movies/countMovies.php`
            

            let response = await axios.get(path , {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer  ${rootGetters['account/jwtToken']}`
                }});
            commit('COUNT_MOVIES', response.data.tableRows);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 404)
                //this.context.rootState.infomessage = error.response.data.message
                console.error(error.response.data.message)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(error.request);
                // this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
                // this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
            }
        }
    },

    async getMovies ({commit, rootState }) {
        try {
            let path = `${rootState.serverConfig.host}/movies/get.php`

            let response = await axios.get(path , {headers: {'Content-Type': 'application/x-www-form-urlencoded' }} )

            if(response.data.records.length > 0)
                commit('SET_MOVIES', response.data.records);
            else
                console.log('fetch:' + response.data.message);                 
            
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 404)
                //this.context.rootState.infomessage = error.response.data.message
                console.error(error.response.data.message)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(error.request);
                // this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
                // this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
            }
        }
    },

    async getMovieDetails ({commit, rootState}, {movieID, userID}) {
        try {

            let path = `${rootState.serverConfig.host}/movies/getMovieDetails.php`
            let response =  await axios.post(path , { userID, movieID }, {headers: {'Content-Type': 'application/x-www-form-urlencoded' }} )

            if(response.data.records.length > 0)
                commit('SET_MOVIES', response.data.records);
            else
                console.log('fetch:' + response.data.message);

        } catch (error) {

            if (error.response) {                
                if (error.response.status === 404)
                // this.context.rootState.infomessage = error.response.data.message
                console.error(error.response.data.message)
            } else if (error.request) {                
                console.error(error.request);
                // this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
            } else {                
                console.error('Error', error.message);
                // this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
            }

        }
    },

    async getUserMovies ({commit, rootState }, userID)  {
        try {
            let path = `${rootState.serverConfig.host}/movies/getUserMovies.php`
            

            await axios.post(path , { userID }, {headers: {'Content-Type': 'application/x-www-form-urlencoded' }} )      
            .then(response => {
                if(response.data.records.length > 0)
                    commit('SET_MOVIES', response.data.records);
                else
                    console.log('fetch:' + response.data.message);
                })
            .catch( (error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    if (error.response.status === 404)
                    this.context.rootState.infomessage = error.response.data.message
                    console.error(error.response.data.message)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.error(error.request);
                    this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                    this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
                }
            });

        } catch (error) {
            console.error("error: " + error);
        }
    },

    imageNotFound ({commit}, index) {
        commit("IMAGE_NOT_FOUND", index);
    },

    addSeenMovies({commit}, id) {
        // change the state of the Movielist
        commit('CHANGE_MOVIE_SEEN' , id);

        // change the state of in the DB
    },

    addBookmark ({commit}, id) {
        commit('CHANGE_MOVIE_BOOKMARK', id);

        // TOdO change the state of in the DB

    }
}

export default {
    namespaced: true,
    mutations,
    getters,
    actions,
    state
};