export default class Movie {
    constructor()
    {
        this.id = null;
        this.titel = null;
        this.titel_deu = null;
        this.overview = null;
        this.popularity = null;
        this.poster_path = null;
        this.release_date = null;        
        this.vote_count = null;
        this.bewertung = null;
        this.bewertung_user = null;
        this.duration = null;
        this.genre_ids = [];
        this.streamservice_id = [];
        this.showMovieTitle = false;
        this.seen = false;
        this.bookmarked = false;
        this.hasTrophy = true;
    }
}